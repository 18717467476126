export const serialUploadListHeadersTitles = [
  'global.series',
  'global.SDP',
  'modules.data-management.serien-daten.upload-list.hist-date',
  'modules.data-management.serien-daten.upload-list.upload-date',
  'global.productive-from',
  'modules.data-management.table-header.user',
  'modules.data-management.serien-daten.upload-list.requirement',
  'global.comment',
];

export const includedSerialUploadListExport = [
  'baureihe',
  'name',
  'histDate',
  'uploadDate',
  'inSeriesSinceSDP',
  'username',
  'requirement',
  'comment',
];
