import { DatePipe } from '@angular/common';
import { HttpClient, HttpResponse,HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@env/paths';
import { MediaType } from '@shared/constants/MediaType';
import { sessionStorageKeys } from '@shared/constants/SessionStorageKeys';
import { ExpertToolCalculation } from '@shared/models/expertool-dynamic-models/ExpertToolCalculation';
import { Observable } from 'rxjs';

import { EnvService } from './env.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class CalculationService {
  private processRequest: any;
  private berechnungBaseUrl = '';
  private berechnungNewUrl = '';
  private berechnungsWorkshopUrl= '';

  constructor(
    private datePipe: DatePipe,
    private httpClient: HttpClient,
    private envService: EnvService,
    private storageService: StorageService
  ) {
    this.berechnungBaseUrl = `${this.envService.apiUrl}${apiPaths.fcscBerechnung}/api/v1`;
    this.berechnungNewUrl = `${this.envService.apiUrl}${apiPaths.fcscBerechnung}/api/v2`;
    this.berechnungsWorkshopUrl = `${this.envService.apiUrl}v1/${apiPaths.fcscWorkshopBerechnung}`;
  }

  private getFin() {
    const vedocData = this.storageService.getData(sessionStorageKeys.vedocData);
    return vedocData?.vin ? vedocData?.vin : vedocData?.fin;
  }

  private generateKey(): string {
    const finVin = this.getFin();
    const date = this.datePipe.transform(new Date(), 'yyyyMMddHHmmss');
    return `${finVin}.${date}`;
  }

  public generateSessionId() {
    return this.generateKey();
  }

  public generateRequestId() {
    return this.generateKey();
  }

  public setProcessRequest(request: any) {
    this.processRequest = request;
  }

  public getProcessRequest() {
    return this.processRequest;
  }

  postCalculateDefaultBdp(
    requestPayload: ExpertToolCalculation, isSums: boolean
  ): Observable<HttpResponse<ExpertToolCalculation>> {
    let url : string;
    if (!this.storageService.getData(sessionStorageKeys.flashForm).abfragen) {
      const index = requestPayload.payload.finLog.metadata.findIndex(item => item.key === "VEDOC")
      if (index !== -1) {
        requestPayload.payload.finLog.metadata.splice(index, 1);
      }
    }
    if(isSums){
      url = `${this.berechnungNewUrl}/calculate/asynch`;
    }else{
      url = `${this.berechnungsWorkshopUrl}`;
    }

    return this.httpClient.post<ExpertToolCalculation>(url, requestPayload, {
      headers: {
        'Content-Type': MediaType.jsonUtf8MediaType,
        Accept: MediaType.jsonUtf8MediaType,
      },
      observe: 'response',
      responseType: 'json',
    });
  }

  postCalculateCustomBdp(
    bdp: string,
    requestPayload: ExpertToolCalculation, isSums: boolean
  ): Observable<HttpResponse<ExpertToolCalculation>> {
    let url : string;
    if(isSums) {
       url = `${this.berechnungNewUrl}/calculate/asynch/${bdp}/api/vp`;
    }else{
      url = `${this.berechnungBaseUrl}/calculate/${bdp}/api/vp`;
    }
    return this.httpClient.post<ExpertToolCalculation>(url, requestPayload, {
      headers: {
        'Content-Type': MediaType.jsonUtf8MediaType,
        Accept: MediaType.jsonUtf8MediaType,
      },
      observe: 'response',
      responseType: 'json',
    });
  }

  putConvertIniToJson(
    requestPayload: any
  ): Observable<HttpResponse<ExpertToolCalculation>> {
    const url = `${this.berechnungBaseUrl}/convert/xml-to-json/ini`;
    return this.httpClient.put<any>(url, requestPayload, {
      headers: {
        'Content-Type': MediaType.textPlainMediaType,
      },
      observe: 'response',
      responseType: 'json',
    });
  }

  putConvertXmlToJson(
    requestPayload: any
  ): Observable<HttpResponse<ExpertToolCalculation>> {
    const url = `${this.berechnungBaseUrl}/convert/xml-to-json`;
    return this.httpClient.put<any>(url, requestPayload, {
      headers: {
        'Content-Type': MediaType.xmlMediaType,
      },
      observe: 'response',
      responseType: 'json',
    });
  }

  putDownloadAsXml(
    requestPayload: ExpertToolCalculation | null
  ): Observable<any> {
    const url = `${this.berechnungBaseUrl}/convert/json-to-xml`;
    return this.httpClient.put(url, requestPayload, {
      headers: {
        'Content-Type': MediaType.jsonUtf8MediaType,
      },
      observe: 'response',
      responseType: 'text',
    });
  }

  putDownloadAsIni(
    requestPayload: ExpertToolCalculation | null
  ): Observable<any> {
    const url = `${this.berechnungBaseUrl}/convert/json-to-xml/ini`;
    return this.httpClient.put(url, requestPayload, {
      headers: {
        'Content-Type': MediaType.jsonUtf8MediaType,
      },
      observe: 'response',
      responseType: 'text',
    });
  }

  postCalculationToFsf(endPoint : string, requestPayload: ExpertToolCalculation | null,params: any): Observable<any> {
    const url = `${this.berechnungBaseUrl}${endPoint}`;
    let paramsVar = new HttpParams();
    if (params) {
      const paramsObj = {};
      Object.keys(params).map((key) => {
        paramsObj[key] = params[key];
      });
      paramsVar = new HttpParams({ fromObject: paramsObj });
    }
    return this.httpClient.post(url, requestPayload, {
      headers:{
        'Content-Type': MediaType.jsonUtf8MediaType,
      },
      observe: 'response',
      responseType: 'json',
      params: paramsVar,
    })
  }
  
  fetchEndPointForCalculationToFsf(hasFsfManipulationEntitlement:boolean) : string{
    return hasFsfManipulationEntitlement ? `/calculate/fsfManipulation` : `/calculate/fsf` 
  }
}
