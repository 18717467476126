export const sessionStorageKeys = {
  flashForm: 'flashForm' as const,
  vedocData: 'vedocData' as const,
  vedocDataCopy: 'vedocDataCopy' as const,
  vedocRecords: 'vedocRecords' as const,
  searchKeyword: 'searchKeyword' as const,
  formData: 'formData' as const,
  calculationData: 'calculationData' as const,
  calculationDataChild: 'calculationDataChild' as const,
  readFile: 'readFile' as const,
  selected: 'selected' as const,
  vin: 'vin' as const,
  detectedBaureihe : 'detectedBaureihe' as const,
  resultSelected: 'result-selected' as const,
  fwResponseData: 'fwResponseData' as const,
  flashwareResponseRecords: 'flashwareResponseRecords' as const,
  selectedBdpName: 'selectedBdpName' as const,
  selectedBdpModalRow: 'selectedBdpModalRow' as const,
  finLogFormData: 'finLogFormData' as const,
  downloadlogForm: 'downloadlogForm' as const,
  populateData: 'populateData' as const,
  fileName: 'fileName' as const,
  directLoad: 'directLoad' as const,
  modelSeries : 'modelSeries' as const,
  baureiheData : 'baureiheData' as const,
  failedBdpCreation : 'failedBdpCreation' as const,
  readType: 'readType' as const,
  removeCertainErrMsgs: 'removeCertainErrMsgs' as const
};
