import { expertToolConstants } from '@shared/constants/ExpertToolConstants';
import { localStorageKeys } from '@shared/constants/LocalStorageKeys';
import { StorageService } from '@core/services/storage.service';
import { sessionStorageKeys } from '@shared/constants/SessionStorageKeys';

export class AscMetadata {
  public sessionID!: string;
  public requestID!: string;
  public platform!: string;
  public service!: string;
  public doRequest!: boolean;
  public version!: string;
  public application = {
    name: expertToolConstants.values.applicationName,
    version: expertToolConstants.values.applicationVersion,
  };

  constructor(
    private storageService: StorageService,
  ) {}
  storedForm = this.storageService.getData(sessionStorageKeys.detectedBaureihe)
  sparte_value = this.storedForm ? this.storedForm.sparte : null;


  

  public task = {
    process: expertToolConstants.values.taskProcess,
    mode: expertToolConstants.values.taskMode,
  };

  public user = {
    userID: expertToolConstants.values.userID,
    organisationID: [expertToolConstants.values.userOrganisationID],
  };
  public destination = {
    service: expertToolConstants.values.destinationService,
    action: expertToolConstants.values.destinationAction,
  };

  public locale = {
    language: localStorage.getItem(localStorageKeys.language),
    country: expertToolConstants.values.localeCountry,
  };



  public payload = {
    category: expertToolConstants.values.payloadCategory,
    poolIdentifier: expertToolConstants.values.payloadPoolIdentifier,
    sparte:this.sparte_value,
    sourceSystem: expertToolConstants.values.payloadSourceSystem,
    SymptomVP: expertToolConstants.values.payloadSymptomVP,
  };


  
}

